import "./Footer.css"

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p>Da Vinci - Andreo, Gueiser -  DWN4AV</p>
      </div>
    </footer>
  );
}

// export default Header;
