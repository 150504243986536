import React from "react";
import "./LoaderMini.css";

export default function LoaderMini() {
  return (
    <span className="loader"></span>
  );
}

// export default Header;
